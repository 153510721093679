export const InstitutionalProductListingSortResults = () => import('./institutional-product-listing-sort-results');
export const InstitutionalProductListingContainer = () => import('./institutional-product-listing-container');
export const InstitutionalProductListingResults = () => import('./institutional-product-listing-results');
export const InstitutionalProductListingFacetedNavigation = () =>
  import('./institutional-product-listing-faceted-navigation');
export const InstitutionalProductListingPagination = () => import('./institutional-product-listing-pagination');
export const InstitutionalProductListingSummaryInformation = () =>
  import('./institutional-product-listing-summary-information');
export const InstitutionalRecipeListingContainer = () => import('./recipes/institutional-recipe-listing-container');
export const InstitutionalRecipeListingResults = () => import('./recipes/institutional-recipe-listing-results');
export const InstitutionalRecipeListingPagination = () => import('./recipes/institutional-recipe-listing-pagination');
export const InstitutionalRecipeListingMenu = () => import('./recipes/institutional-recipe-listing-menu');
export const InstitutionalRecipeListingFacetedNavigation = () =>
  import('./recipes/institutional-recipe-listing-faceted-navigation');

export const InstitutionalProductListingContainerThreeColumns = () =>
  import('./institutional-product-listing-container-three-coloumns');
export const InstitutionalProductListingResultsThreeColumns = () =>
  import('./institutional-product-listing-results-three-columns');
export const InstitutionalProductListingFiltersContainer = () =>
  import('./institutional-product-listing-filters-container');
